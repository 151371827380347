<template>
  <div class="era-catalytic-container">
    <div class="era-catalytic-introduce">
      <div class="era-catalytic-text">
        <div class="era-catalytic-title">微观纪元 |开算> 平台</div>
        <div class="era-catalytic-content">微观纪元开发的基于云原生技术的量子计算应用软件，着力于将量子算法应用于生命科学、材料科学等领域中，
          本软件面向开发者和用户，通过发挥量子算法与经典算法的各自优势，融合经典与量子算力资源，更高效能地解决实际问题。</div>
      </div>
      <div class="era-catalytic-pic">
        <img
          width="90%"
          height="100%"
          src="@/assets/images/catalytic-03.png"
        />
      </div>
    </div>
    <div class="era-catalytic-video">
      <img
        width="100%"
        height="100%"
        style="margin-bottom: 4vw;"
        src="@/assets/images/catalytic-bgm.png"
      >
      <video
        muted
        class="hcs-video-show"
        loop="loop"
        autoplay="autoplay"
        preload="auto"
        controls="autoplay"
      >
        <source
          src="@/assets/images/plat-video.mp4"
          type="video/mp4"
        >
      </video>
    </div>
  </div>
</template>
<script>
export default {
  name: "Catalytic",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.era-catalytic-container {
  .era-catalytic-introduce {
    display: flex;
    margin: 5vw 2vw 0 2vw;
    .era-catalytic-text {
      .era-catalytic-title {
        font-size: 2.1vw;
        font-weight: bold;
        color: #000000;
        margin: 5vw 0 0 5vw;
      }
      .era-catalytic-content {
        margin: 2vw 5vw 0;
        font-size: 1.1vw;
        font-weight: 400;
        color: rgb(0, 0, 0);
        line-height: 2.1vw;
      }
    }
  }
  .era-catalytic-video {
    img{
      position: relative;
    }
    video {
      height: 30%;
      position: absolute;
      width: 90%;
      z-index: 100;
      top: 60vw;
      left: 5vw;
    }
  }
}
</style>
